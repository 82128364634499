import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ButtonComp from "../pages/ButtonComp";
import TagComp from "../pages/TagComp";
import RadioComp from "../pages/RadioComp";
import CheckBoxComp from "../pages/CheckBoxComp";
import AvatarComp from "../pages/AvatarComp";
import TabComp from "../pages/TabComp";
import { GlobalProvider } from "../provider/GlobalProvider";
import Layout from "../theme/Layout/Layout";
import ToasterComp from './../pages/ToasterComp';
import InputComp from "../pages/InputComp";
import TextAreaComp from "../pages/TextAreaComp";
import AvatarGroup from "../pages/AvatarGroupComp";

function Index() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout title="button">
          <ButtonComp />
        </Layout>
      ),
    },
    {
      path: "/tag",
      element: (
        <Layout title="tag">
          <TagComp />
        </Layout>
      ),
    },
    {
      path: "/radio",
      element: (
        <Layout title="radio">
          <RadioComp />
        </Layout>
      ),
    },
    {
      path: "/checkbox",
      element: (
        <Layout title="chekbox">
          <CheckBoxComp />
        </Layout>
      ),
    },
    {
      path: "/avatar",
      element: (
        <Layout title="avatar">
          <AvatarComp />
        </Layout>
      ),
    },
    {
      path: "/avatargroup",
      element: (
        <Layout title="avatar group">
          <AvatarGroup />
        </Layout>
      ),
    },
    {
      path: "/tab",
      element: (
        <Layout title="tab">
          <TabComp />
        </Layout>
      ),
    },
    {
      path: "/toaster",
      element: (
        <Layout title="toaster">
          <ToasterComp />
        </Layout>
      ),
    },
    {
      path: "/input",
      element: (
        <Layout title="input">
          <InputComp />
        </Layout>
      ),
    },
    {
      path: "/textarea",
      element: (
        <Layout title="textarea">
          <TextAreaComp />
        </Layout>
      ),
    },
  ]);

  return (
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  );
}

export default Index;
