import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Button from '../../theme/Elements/Button/Button'

function LinkButton() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
  const buttonSizes = [
    {  title: 'extra large',desc:commonDesc },
    {  title: 'large',desc:commonDesc },
    {  title: 'normal',desc:commonDesc },
    {  title: 'dense',desc:commonDesc },
    {  title: 'denser',desc:commonDesc },
  ];

  return (
    <div>
      <h3 className="section-title">link</h3>
      <p className="comp-desc mt-12">
        Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a
        page. Primary buttons should only appear once per container (not including the application header or in a modal
        dialog). Not every screen requires a primary button.
      </p>
      <div className="d-flex mt-32 flex-column gap-32">
        {buttonSizes.map(({ title,desc }) => (
          <CompLayout
            key={title}
            component={<Button variant="link" />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  )
}

export default LinkButton