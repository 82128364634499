import React from 'react';
import { UserIcon } from '../../icons';

function Avatar({ content, image, text = "AA", state, size, avatarGroup, avatarData }) {
  // state :: status || presence

  return (
    <div className={`${avatarGroup ? "avatar-group" : "avatar-badge"}`}>
      {avatarGroup ? (
        <>
          {avatarData.length > 4 && (
            <div className={`avatar avatar-circle avatar-group-count ${size ? "avatar-" + size : ""}`}>{avatarData.length - 4}+</div>
          )}
          {avatarData.slice(0, 4).map(({ key, type, text, image }) => (
            <div key={key} className={`avatar avatar-circle ${size ? "avatar-" + size : ""}`}>
              {type === "text" ? (
                <div className="avatar-text">{text}</div>
              ) : type === "icon" ? (
                <UserIcon />
              ) : (
                <img
                  src={image || "/images/svg/avatar-image.svg"}
                  alt="avatar"
                  className="avatar-image"
                />
              )}
            </div>
          ))}
        </>
      ) : (
        <div className={`avatar avatar-circle ${size ? "avatar-" + size : ""}`}>
          {content === "text" ? (
            text
          ) : content === "icon" ? (
            <UserIcon />
          ) : (
            <img src={image || "/images/svg/avatar-image.svg"} alt="avatar" className="avatar-image" />
          )}
        </div>
      )}
      {state === "status" && !avatarGroup && (
        <span className="avatar-status">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M2 5.75L4.83413 8.5L10.5 3"
              stroke="#171717"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
      {state === "presence" && !avatarGroup && <span className="avatar-active"></span>}
    </div>
  );
}

export default Avatar;
