import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import TextArea from '../../theme/Elements/TextArea/TextArea'

function FluidTextArea() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button."
  const textAreaConfigurations = [
    { title: "Normal default", desc: commonDesc },
    { title: "Normal error", validation: true, desc: commonDesc }
  ];

  return (
    <div>
      <h3 className="section-title">fluid</h3>
      <p className="comp-desc mt-12">{commonDesc}</p>
      <div className="d-flex mt-32 flex-column gap-32">
        {textAreaConfigurations.map(({ title, validation, desc }) => (
          <CompLayout
            key={title}
            component={<TextArea fluid validation={validation} />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  )
}

export default FluidTextArea