import React from 'react'
import DefaultInput from '../components/Input/DefaultInput'

function InputComp() {
  return (
    <div className="d-flex flex-column gap-52">
<DefaultInput />
</div>
  )
}

export default InputComp