// import React, { useContext, useEffect } from 'react'
import style from './layout.module.scss';
// import { GlobalContext } from '../../provider/GlobalProvider';
// import { DarkTheme, LightTheme } from '../icons';
import { Link, useLocation } from 'react-router-dom';
function Layout({children,title}) {
  // const {theme,setTheme} = useContext(GlobalContext)
  // useEffect(() => {
  //   document.documentElement.setAttribute('data-theme', theme);
  // }, [theme]);
  const location = useLocation();
  const Links = [
    {
      key:'1',
      link:'/',
      title:'button'
    },
    {
      key:'2',
      link:'/radio',
      title:'radio'
    },
    {
      key:'3',
      link:'/checkbox',
      title:'checkbox'
    },
    {
      key:'4',
      link:'/tag',
      title:'tag'
    },
    {
      key:'5',
      link:'/avatar',
      title:'avatar'
    },
    {
      key:'6',
      link:'/avatargroup',
      title:'avatar group'
    },
    {
      key:'7',
      link:'/tab',
      title:'tab'
    },
    {
      key:'8',
      link:'/toaster',
      title:'toaster'
    },
    {
      key:'9',
      link:'/input',
      title:'input'
    },
    {
      key:'10',
      link:'/textarea',
      title:'textarea'
    },
  ]
  return (
    <div  className={style["main-container"]}>
      <nav className={style["navbar"]}>
<Link to={'/'} className={style["nav-logo"]}>
  <img src={`/images/svg/logo-light.svg`} alt="" />
  {/* <img src={`/images/svg/${theme === 'dark' ? "logo-dark" : "logo-light"}.svg`} alt="logo" /> */}
</Link>
<h6 className={style["component-text"]}>Components</h6>
<div className={style["link-wrapper"]}>
{
  Links.map((link)=>{
    return(
      <Link key={link.key} to={link.link} className={`${style["nav-link"]} ${location.pathname === link.link && style["active"]}`}>
                {link.title}
              </Link>
    )
  })
}
</div>
      </nav>
      <main className={style["main-content"]}>
        <header className={style["header"]}>
        <h4 className={style["component-title"]}>{title}</h4>
          {/* <button onClick={()=>{
            setTheme(theme === 'light' ? 'dark' : 'light')
          }} className={style["theme-btn"]}>
            {theme === "dark" ? <LightTheme /> : <DarkTheme />}
          </button> */}
        </header>
        <section className={style["content-section"]}>
{children}
        </section>
      </main>
    </div>
  )
}

export default Layout