import React, { useState } from 'react'

function Tab({dataItem,variant}) {
  const [activeTab,setActiveTab] = useState(0)
  return (
    <div className='tab'>
<div className={`tab-header ${variant ? "tab-header-"+variant : ""}`}>
{
  dataItem.map((item)=>{
    return(
      <button className={`tab-btn ${activeTab === item.key-1 ? 'active':""} ${variant ? "tab-btn-"+variant : ""}`} onClick={()=>{
        setActiveTab(item.key-1)
      }}>
      {
        item.prefix && <span className="prefix-icon">
          {item.prefix}
        </span>
      }
        {item.label}
        {
        item.badge && <span className="tab-btn-badge">
          {item.badge}
        </span>
      }
        {
        item.suffix && <span className="suffix-icon">
          {item.suffix}
        </span>
      }
      </button>
    )
  })
}
</div>
<div className="tab-body">
  {
    dataItem[activeTab].content
  }
</div>
    </div>
  )
}

export default Tab