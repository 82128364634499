export const LightTheme = () =>{
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2_1391)">
<path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 1V3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 21V23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.21997 4.21997L5.63997 5.63997" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.36 18.36L19.78 19.78" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 12H3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 12H23" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.21997 19.78L5.63997 18.36" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.36 5.63997L19.78 4.21997" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2_1391">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}
export const DarkTheme = () =>{
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 12.79C20.8427 14.4922 20.2039 16.1144 19.1583 17.4668C18.1127 18.8192 16.7035 19.8458 15.0957 20.4265C13.4879 21.0073 11.748 21.1181 10.0795 20.7461C8.41104 20.3741 6.88302 19.5345 5.67425 18.3258C4.46548 17.117 3.62596 15.589 3.25393 13.9205C2.8819 12.252 2.99274 10.5121 3.57348 8.9043C4.15423 7.29651 5.18085 5.88737 6.53324 4.84175C7.88562 3.79614 9.50782 3.15731 11.21 3C10.2134 4.34827 9.73387 6.00945 9.85856 7.68141C9.98324 9.35338 10.7039 10.9251 11.8894 12.1106C13.075 13.2961 14.6466 14.0168 16.3186 14.1415C17.9906 14.2662 19.6518 13.7866 21 12.79V12.79Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export const CopyIcon = () =>{
  return(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g mask="url(#mask0_3891_850)">
<path d="M2.5 15.4199V13.7533H4.16667V15.4199H2.5ZM2.5 12.5033V10.8366H4.16667V12.5033H2.5ZM2.5 9.58659V7.91992H4.16667V9.58659H2.5ZM5.41667 18.3366V16.6699H7.08333V18.3366H5.41667ZM7.5 15.0033C7.04167 15.0033 6.64931 14.8401 6.32292 14.5137C5.99653 14.1873 5.83333 13.7949 5.83333 13.3366V3.33659C5.83333 2.87826 5.99653 2.48589 6.32292 2.15951C6.64931 1.83312 7.04167 1.66992 7.5 1.66992H15C15.4583 1.66992 15.8507 1.83312 16.1771 2.15951C16.5035 2.48589 16.6667 2.87826 16.6667 3.33659V13.3366C16.6667 13.7949 16.5035 14.1873 16.1771 14.5137C15.8507 14.8401 15.4583 15.0033 15 15.0033H7.5ZM7.5 13.3366H15V3.33659H7.5V13.3366ZM8.33333 18.3366V16.6699H10V18.3366H8.33333ZM4.16667 18.3366C3.70833 18.3366 3.31597 18.1734 2.98958 17.847C2.66319 17.5206 2.5 17.1283 2.5 16.6699H4.16667V18.3366ZM11.25 18.3366V16.6699H12.9167C12.9167 17.1283 12.7535 17.5206 12.4271 17.847C12.1007 18.1734 11.7083 18.3366 11.25 18.3366ZM2.5 6.66992C2.5 6.21159 2.66319 5.81923 2.98958 5.49284C3.31597 5.16645 3.70833 5.00326 4.16667 5.00326V6.66992H2.5Z" fill="#151819"/>
</g>
</svg>

  )
}

export const ButtonIcon = (props) =>{
  const color = props.color
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke={color} stroke-width="1.5"/>
<path d="M5.5 5.5L18.3615 18.3615" stroke={color} stroke-width="1.5"/>
<path d="M18.3563 5.5L5.5 18.3563" stroke={color} stroke-width="1.5"/>
</svg>

  )
}

export const CloseIcon = (props) =>{
  return(
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M6.66484 1.33667L1.33855 6.6612" stroke="#434343" stroke-width="1.5" stroke-linecap="round"/>
<path d="M6.66666 6.66671L1.33333 1.33337" stroke="#434343" stroke-width="1.5" stroke-linecap="round"/>
</svg>

  )
}
export const ToasterCloseIcon = (props) =>{
  return(
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M19.9945 4.00977L4.01562 19.9834" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
<path d="M20 20L4 4" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
</svg>

  )
}

export const DropDown = () =>{
  return(
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6668 6.16699L8.00016 10.8337L3.3335 6.16699" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
export const TickIcon = () =>{
  return(
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.5" width="20" height="20" rx="10" fill="#0A8A00"/>
<path d="M6 10.25L8.83413 13L14.5 7.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  )
}
export const ErrorIcon = () =>{
  return(
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.6665 8.00033C2.6665 5.05686 5.05584 2.66699 7.99984 2.66699C10.9492 2.66699 13.3332 5.05686 13.3332 8.00033C13.3332 10.9449 10.9492 13.3337 7.99984 13.3337C5.05584 13.3337 2.6665 10.9449 2.6665 8.00033ZM9.96681 9.96353C10.1886 9.74257 10.1886 9.38406 9.96681 9.16244L8.80555 8.0022L9.96681 6.8413C10.1886 6.62034 10.1886 6.25532 9.96681 6.0337C9.745 5.81142 9.38618 5.81142 9.15784 6.0337L8.0031 7.19329L6.84184 6.0337C6.6135 5.81142 6.25468 5.81142 6.03286 6.0337C5.81105 6.25532 5.81105 6.62034 6.03286 6.8413L7.19413 8.0022L6.03286 9.15593C5.81105 9.38406 5.81105 9.74257 6.03286 9.96353C6.14377 10.0743 6.29382 10.1337 6.43735 10.1337C6.5874 10.1337 6.73093 10.0743 6.84184 9.96353L8.0031 8.81046L9.16436 9.96353C9.27527 10.0815 9.4188 10.1337 9.56232 10.1337C9.71238 10.1337 9.8559 10.0743 9.96681 9.96353Z" fill="#D31510"/>
</svg>



  )
}

export const UserIcon = () =>{
  return(
    <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.882 15.8732C39.882 24.6842 32.8173 31.7493 24 31.7493C15.1857 31.7493 8.11804 24.6842 8.11804 15.8732C8.11804 7.06207 15.1857 0 24 0C32.8173 0 39.882 7.06207 39.882 15.8732ZM24 60C10.9871 60 0 57.885 0 49.7249C0 41.5618 11.0561 39.5218 24 39.5218C37.0159 39.5218 48 41.6368 48 49.7969C48 57.96 36.9439 60 24 60Z" fill="white"/>
</svg>

  )
}