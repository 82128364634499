import React from 'react'
import { ButtonIcon, CloseIcon } from '../../icons'

function Tag({size,label="Basic tag",disable,prefixIcon,prefixClass,mode,counterClass,closeIconClass,counter=0}) {
  return (
    <div className={`tag ${size ? "tag-"+size : ""} ${mode == "closable" ? 'tag-closable' : ""} ${disable ? 'disabled' : ""}`}>
    {
      prefixIcon && (
        prefixIcon == true || false ? <span className={`prefix-icon ${prefixClass ? prefixClass : ""}`}>
        <ButtonIcon color={disable ? '#17171740' : 'black'}/>
      </span> : <span className={` ${prefixClass ? prefixClass : ""}`}>
        {prefixIcon}
      </span> 
      )
    }
      <span className={`tag-label`}>{label}</span>
      {
        mode == "counter" ? <span className={`tag-counter ${counterClass ? counterClass : ""}`}>| {counter}</span> : 
        mode == "closable" ? <span className={`close-icon ${closeIconClass ? closeIconClass : ""}`}>
          <CloseIcon />
        </span> : null
      }
    </div>
  )
}

export default Tag