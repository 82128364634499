import React from 'react'
import DefaultGroup from '../components/AvatarGroup/DefaultGroup'

function AvatarGroupComp() {
  return (
    <div className="d-flex flex-column gap-52">
    <DefaultGroup />
    </div>
  )
}

export default AvatarGroupComp