import React from 'react'
import DefaultCheckBox from '../components/CheckBox/DefaultCheckBox'
import OutlineCheckBox from '../components/CheckBox/OutlineCheckBox'

function CheckBoxComp() {
  return (
    <div className="d-flex flex-column gap-52">
<DefaultCheckBox />
<OutlineCheckBox />
    </div>
  )
}

export default CheckBoxComp