import React from 'react'
import DefaultTab from '../components/Tab/DefaultTab'
import PillTab from './../components/Tab/PillTab';

function TabComp() {
  return (
    <div className="d-flex flex-column gap-52">
<DefaultTab />
<PillTab />
        </div>
  )
}

export default TabComp