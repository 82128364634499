import React from 'react'
import DefaultRadio from '../components/Radio/DefaultRadio'
import OutlineRadio from '../components/Radio/OutlineRadio'

function RadioComp() {
  return (
    <div className="d-flex flex-column gap-52">
    <DefaultRadio />
    <OutlineRadio />
    </div>
  )
}

export default RadioComp