import React from 'react';
import CompLayout from '../../theme/Layout/CompLayout';
import Toaster from '../../theme/Elements/Toaster/Toaster';

function NormalToaster() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button."
  
  const toasterConfigurations = [
    { title: "Normal", desc: commonDesc },
    { title: "Normal with leading icon", prefix: true, desc: commonDesc },
    { title: "Normal with closable icon", closable: true, desc: commonDesc },
    { title: "Normal with leading and closable icon", prefix: true, closable: true, desc: commonDesc },
    { title: "Normal with actions", action: true, desc: commonDesc },
    { title: "Normal with actions and leading icon", action: true, prefix: true, desc: commonDesc },
    { title: "Normal with actions and closable icon", action: true, closable: true, desc: commonDesc },
    { title: "Normal with actions, leading and closable icon", action: true, prefix: true, closable: true, desc: commonDesc },
    { title: "Normal with wrapped actions", action: true, actionFooter: true, desc: commonDesc },
    { title: "Normal with leading icon and wrapped actions", action: true, prefix: true, actionFooter: true, desc: commonDesc },
    { title: "Normal with wrapped actions and closable icon", action: true, actionFooter: true, closable: true, desc: commonDesc },
    { title: "Normal with leading icon, wrapped actions and closable icon", action: true, prefix: true, actionFooter: true, closable: true, desc: commonDesc }
  ];

  return (
    <div>
      <h3 className="section-title">default</h3>
      <p className="comp-desc mt-12">{commonDesc}</p>
      <div className="d-flex mt-32 flex-column gap-32">
        {toasterConfigurations.map(({ title, prefix, closable, action, actionFooter, desc }) => (
          <CompLayout
            key={title}
            component={<Toaster prefix={prefix} closable={closable} action={action} actionFooter={actionFooter} />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  );
}

export default NormalToaster;
