import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Avatar from '../../theme/Elements/Avatar/Avatar'

function TextAvatar() {
  const avatarComp = [
    {title : "normal" , state : undefined },
    {title : "Normal with status" , state : "status" },
    {title : "Normal with presence" , state : "presence" },
  ]
  return (
    <div>
    <h3 className="section-title">text</h3>
    <p className="comp-desc mt-12">Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.</p>
    <div className="d-flex mt-32 flex-column gap-32">
    {
      avatarComp.map(({title,state})=>{
        return(
          <CompLayout
            key={title}
            component={<Avatar content="text" text='AA' size="xl" state={state}/>}
            title={title}
            desc="Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
          />
        )
      })
    }
    </div>
  </div>
  )
}

export default TextAvatar