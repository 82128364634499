import React from 'react';
import CompLayout from '../../theme/Layout/CompLayout';
import Button from '../../theme/Elements/Button/Button';

function ButtonWithIcon() {
  const buttonConfigs = [
    { prefixIcon:true,suffixIcon:false, title: 'Icon before' },
    { prefixIcon:false,suffixIcon:true, title: 'Icon after' },
    { prefixIcon:true,suffixIcon:true,title: 'Fluid width large',fluid:true }
  ];
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."

  return (
    <div>
      <h3 className="section-title">Button with icon</h3>
      <p className="comp-desc mt-12">
        Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a
        page. Primary buttons should only appear once per container (not including the application header or in a modal
        dialog). Not every screen requires a primary button.
      </p>
      <div className="d-flex mt-32 flex-column gap-32">
        {buttonConfigs.map(({ prefixIcon,suffixIcon, title,fluid }) => (
          <CompLayout
            key={title}
            component={<Button variant="primary" prefixIcon={prefixIcon} suffixIcon={suffixIcon} size="xl" fluid={fluid}/>}
            title={title}
            desc={commonDesc}
          />
        ))}
      </div>
    </div>
  );
}

export default ButtonWithIcon;
