import React from 'react'
import DefaultAvatar from '../components/Avatar/DefaultAvatar'
import SilhouetteAvatar from '../components/Avatar/SilhouetteAvatar'
import TextAvatar from '../components/Avatar/TextAvatar'
import AvatarSizes from '../components/Avatar/AvatarSizes'

function AvatarComp() {
  return (
    <div className="d-flex flex-column gap-52">
<DefaultAvatar />
<SilhouetteAvatar />
<TextAvatar />
<AvatarSizes />
    </div>
  )
}

export default AvatarComp