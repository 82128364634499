import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Button from '../../theme/Elements/Button/Button'

function SecondaryButton() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
  const buttonSizes = [
    { size: 'xl', title: 'extra large',desc:commonDesc },
    { size: 'lg', title: 'large',desc:commonDesc },
    { size: undefined, title: 'normal',desc:commonDesc },
    { size: 'sm', title: 'dense',desc:commonDesc },
    { size: 'xs', title: 'denser',desc:commonDesc },
    { size: 'xl', title: 'Fluid width large',desc:commonDesc,fluid:true }
  ];

  return (
    <div>
      <h3 className="section-title">secondary</h3>
      <p className="comp-desc mt-12">
        Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a
        page. Primary buttons should only appear once per container (not including the application header or in a modal
        dialog). Not every screen requires a primary button.
      </p>
      <div className="d-flex mt-32 flex-column gap-32">
        {buttonSizes.map(({ size, title,desc,fluid }) => (
          <CompLayout
            key={title}
            component={<Button variant="secondary" size={size} fluid={fluid}/>}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  )
}

export default SecondaryButton