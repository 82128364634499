import React from 'react';
import Input from '../../theme/Elements/Input/Input';
import CompLayout from '../../theme/Layout/CompLayout';

function DefaultInput() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
  const inputConfigurations = [
    { title: "Normal", desc: commonDesc },
    { title: "Normal with leading icon", leadingIcon: true, desc: commonDesc },
    { title: "Normal with trailing icon", tailingIcon: true, desc: commonDesc },
    { title: "Normal with leading and trailing icon", leadingIcon: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal prefix", prefix: true, desc: commonDesc },
    { title: "Normal prefix with leading icon", prefix: true, leadingIcon: true, desc: commonDesc },
    { title: "Normal prefix with trailing icon", prefix: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal prefix with leading and trailing icon", prefix: true, leadingIcon: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal suffix", suffix: true, desc: commonDesc },
    { title: "Normal suffix with leading icon", suffix: true, leadingIcon: true, desc: commonDesc },
    { title: "Normal suffix with trailing icon", suffix: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal suffix with leading and trailing icon", suffix: true, leadingIcon: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal prefix and suffix", prefix: true, suffix: true, desc: commonDesc },
    { title: "Normal prefix and suffix with leading icon", prefix: true, suffix: true, leadingIcon: true, desc: commonDesc },
    { title: "Normal prefix and suffix with trailing icon", prefix: true, suffix: true, tailingIcon: true, desc: commonDesc },
    { title: "Normal prefix and suffix with leading and trailing icon", prefix: true, suffix: true, leadingIcon: true, tailingIcon: true, desc: commonDesc },
  ];

  return (
    <div>
      <h3 className="section-title">Default</h3>
      <p className="comp-desc mt-12">{commonDesc}</p>
      <div className="d-flex mt-32 flex-column gap-32 w-100">
        {inputConfigurations.map(({ title, prefix, leadingIcon, tailingIcon, suffix, desc }) => (
          <CompLayout
            key={title}
            component={<Input prefix={prefix} leadingIcon={leadingIcon} tailingIcon={tailingIcon} suffix={suffix} />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  );
}

export default DefaultInput;
