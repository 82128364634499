import React, { useRef, useState } from 'react';
import { ButtonIcon, ErrorIcon } from '../../icons';

function Input({ placeholder = 'value', disabled, value, readOnly, tailingIcon, leadingIcon, prefix, suffix, validation = true }) {
  const [inputValue, setInputValue] = useState(value);
  const [isFocus, setFocus] = useState(false)
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const handleFocus = () => {
    if (!disabled) {
      setFocus(true)
      inputRef.current.focus();
    }
  }
  const handleBlur = () => {
    setFocus(false);
    if (validation && inputValue == null) {
      setError(true)
    }
    else if (validation) {
      validateInput(inputValue);
    }
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (validation) {
      validateInput(e.target.value);
    }
  };
  const validateInput = (value) => {
    if (value.length < 1) {
      setError('Input must be at least 5 characters long');
    } else {
      setError(null);
    }
  };
  return (
    <div className={`input-wrapper input ${disabled ? 'disabled' : ""} ${readOnly ? 'read-only' : ""}`}>
      <label htmlFor="input" className="input-label">
        label
      </label>
      <div onClick={handleFocus} className={`input-container ${isFocus ? 'focused' : ""} ${error ? 'error' : ""}`}>
        {
          leadingIcon && (
            leadingIcon == true || false ? <span className="">
              <ButtonIcon color="#171717" width="16px" height="16px" />
            </span> : leadingIcon
          )
        }
        {
          prefix && (
            prefix == true || false ? <p className="prefix-text">Prefix</p> : prefix
          )
        }
        <input
          ref={inputRef}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={inputValue}
          disabled={disabled}
          type="text"
          placeholder={placeholder}
          id="input"
          className="input-field"
        />
        {
          suffix && (suffix == true || false ? <p className="prefix-text">Suffix</p> : suffix)
        }
        {
          tailingIcon && (
            tailingIcon == true || false ? <span className="">
              <ButtonIcon color="#171717" width="16px" height="16px" />
            </span> : tailingIcon
          )
        }
      </div>
      <div className={`error-container ${error && 'active'}`}>
        <span className="error-icon"><ErrorIcon /></span>
        <h6 className="error-message">Helpful validation message</h6>
      </div>
    </div>
  );
}

export default Input;
