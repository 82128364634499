import React from 'react'
import { Link } from 'react-router-dom'
import DefaultTag from '../components/Tag/DefaultTag'
import LargeTag from '../components/Tag/LargeTag'

function TagComp() {
  return (
    <div className="d-flex flex-column gap-52">
    <DefaultTag />
    <LargeTag />
    </div>
  )
}

export default TagComp