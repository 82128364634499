import React from 'react'

function Radio({variant="default",size,disabled}) {
  // variant : default,Outline
  // size : large,small
  return (
    <input disabled={disabled} className={`radio radio-${variant} ${disabled ? 'radio-disabled':""} ${size ? "radio"+size : ""}`} type="radio" name={`${variant}-${size}`} id={`${variant}-${size}`} />
  )
}

export default Radio