import React from "react";
import CompLayout from "../../theme/Layout/CompLayout";
import Tab from "../../theme/Elements/Tab/Tab";
import { ButtonIcon, DropDown } from "../../theme/icons";
import DefaultAvatar from "./../Avatar/DefaultAvatar";

function DefaultTab() {
  const commonDesc =
    "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.";

  // Consolidate tab content data with varying configurations
  const tabsData = [
    {
      title: "Normal",
      desc: commonDesc,
      dataItem: [
        { key: 1, label: "Tab title 1", content: <div>content 1</div> },
        { key: 2, label: "Tab title 2", content: <div>content 2</div> },
        { key: 3, label: "Tab title 3", content: <div>content 3</div> },
      ],
    },
    {
      title: "Normal with leading icon",
      desc: commonDesc,
      dataItem: [
        {
          key: 1,
          label: "Tab title 1",
          prefix: <ButtonIcon />,
          content: <div>content 1</div>,
        },
        {
          key: 2,
          label: "Tab title 2",
          prefix: <ButtonIcon />,
          content: <div>content 2</div>,
        },
        {
          key: 3,
          label: "Tab title 3",
          prefix: <ButtonIcon />,
          suffix: <DropDown />,
          content: <div>content 3</div>,
        },
      ],
    },
    {
      title: "Normal with tailing icon",
      desc: commonDesc,
      dataItem: [
        {
          key: 1,
          label: "Tab title 1",
          badge: "New",
          content: <div>content 1</div>,
        },
        {
          key: 2,
          label: "Tab title 2",
          badge: "Updated",
          content: <div>content 2</div>,
        },
        {
          key: 3,
          label: "Tab title 3",
          badge: "15",
          content: <div>content 3</div>,
        },
      ],
    },
  ];

  return (
    <div>
      <h3 className="section-title">Default</h3>
      <p className="comp-desc mt-12">{commonDesc}</p>
      <div className="d-flex mt-32 flex-column gap-32">
        {tabsData.map(({ title, desc, dataItem }, index) => (
          <CompLayout
            key={index}
            component={<Tab dataItem={dataItem} />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  );
}

export default DefaultTab;
