import React from 'react';
import { ButtonIcon } from '../../icons';

function Button({ text = "button", variant, size, disabled, suffixIcon, prefixIcon, prefixClass, suffixClass, onClick, fluid }) {
  const variantClass = variant ? `btn-${variant}` : '';
  const sizeClass = size ? `btn-${size}` : '';
  const fluidClass = fluid ? 'btn-fluid' : '';
  const iconClass = (prefixIcon || suffixIcon) ? 'btn-icon' : '';

  return (
    <button onClick={onClick} className={`btn ${variantClass} ${sizeClass} ${fluidClass} ${disabled ? 'disabled' : ''} ${iconClass}`}>
      {prefixIcon && (
        <span className={`prefix-icon ${prefixClass}`}>
          {prefixIcon === true ? <ButtonIcon color="#FFFFFF" /> : prefixIcon}
        </span>
      )}
      {text}
      {suffixIcon && (
        <span className={`suffix-icon ${suffixClass}`}>
          {suffixIcon === true ? <ButtonIcon color="#FFFFFF" /> : suffixIcon}
        </span>
      )}
    </button>
  );
}

export default Button;
