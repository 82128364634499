import React, { useRef, useState } from 'react';
import { ErrorIcon } from '../../icons';

function TextArea({ placeholder = 'value', disabled, value, readOnly, validation, fluid }) {
  const [inputValue, setInputValue] = useState(value);
  const [isFocus, setFocus] = useState(false)
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const maxCount = 20;
  const handleFocus = () => {
    if (!disabled) {
      setFocus(true)
      inputRef.current.focus();
    }
  }
  const handleBlur = () => {
    setFocus(false);
    if (validation && inputValue ==
      null) {
      setError(true)
    }
    else if (validation) {
      validateInput(inputValue);
    }
  };
  const handleChange = (e) => {

    const newValue = e.target.value;
    if (newValue.length <= maxCount) {
      setInputValue(newValue);
      if (validation) {
        validateInput(e.target.value);
      }
    }
  };
  const validateInput = (value) => {
    if (value.length < 1) {
      setError('Input must be at least 5 characters long');
    } else {
      setError(null);
    }
  };
  return (
    <div className={`textarea-wrapper textarea  ${disabled ? 'textarea-disabled' : ""} ${readOnly ? 'textarea-read-only' : ""} ${fluid ? 'textarea-fluid' : ""}`}>
      {
        !fluid && <label htmlFor="textarea" className="textarea-label">
          label
        </label>
      }
      <div onClick={handleFocus} className={`textarea-container ${isFocus ? 'focused' : ""} ${error ? 'error' : ""}`}>
        {
          fluid && <label htmlFor="textarea" className="textarea-label">
            label
          </label>
        }
        <textarea
          ref={inputRef}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={inputValue}
          disabled={disabled}
          type="text"
          placeholder={fluid ? "" : placeholder}
          id="textarea"
          className="textarea-field"
        />
      </div>
      {
        !validation &&
        <div className="count-container">
          <h6 className="count-text">Helper text</h6>
          <h6 className="count-text">{inputValue ? inputValue.length : "0"}/20</h6>
        </div>
      }
      <div className={`error-container ${error ? 'active' : ""}`}>
        <span className="error-icon"><ErrorIcon /></span>
        <h6 className="error-message">Error</h6>
      </div>
    </div>
  );
}

export default TextArea;
