import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Tag from '../../theme/Elements/Tag/Tag'

function LargeTag() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
  const tagConfigurations = [
    { title: "large label only", desc: commonDesc },
    { title: "large label with counter", mode: "counter", desc: commonDesc },
    { title: "large label with leading icon", prefixIcon: true, desc: commonDesc },
    { title: "large label with counter and leading icon", mode: "counter", prefixIcon: true, desc: commonDesc },
    { title: "large removable tag", mode: "closable", desc: commonDesc },
    { title: "large removable tag with leading icon", mode: "closable", prefixIcon: true, desc: commonDesc }
  ];

  return (
    <div>
      <h3 className="section-title">large</h3>
      <p className="comp-desc mt-12">Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.</p>
      <div className="d-flex mt-32 flex-column gap-32">
        {tagConfigurations.map(({ title, mode, prefixIcon, desc }) => (
          <CompLayout
            key={title}
            component={<Tag mode={mode} size="large" prefixIcon={prefixIcon} />}
            title={title}
            desc={desc}
          />
        ))}
      </div>
    </div>
  )
}

export default LargeTag