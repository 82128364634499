import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Avatar from '../../theme/Elements/Avatar/Avatar'

function DefaultGroup() {
  const AvatarComp = [
    {
      title: "extra large",
      size: "xl",
      data: [
        { key: 1, type: "image" },
        { key: 2, type: "image" },
        { key: 3, type: "image" },
        { key: 4, type: "image" },
        { key: 5, type: "image" },
        { key: 6, type: "image" },
        { key: 7, type: "image" },
      ]
    },
    {
      title: "large",
      size: "xl",
      data: [
        { key: 1, type: "image" },
        { key: 2, type: "image" },
        { key: 3, type: "image" },
        { key: 4, type: "image" },
        { key: 5, type: "image" },
        { key: 6, type: "image" },
        { key: 7, type: "image" },
      ]
    },
    {
      title: "small",
      size: "xl",
      data: [
        { key: 1, type: "image" },
        { key: 2, type: "image" },
        { key: 3, type: "image" },
        { key: 4, type: "image" },
        { key: 5, type: "image" },
        { key: 6, type: "image" },
        { key: 7, type: "image" },
      ]
    }
  ]
  return (
    <div>
      <h3 className="section-title">default</h3>
      <p className="comp-desc mt-12">Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.</p>
      <div className="d-flex mt-32 flex-column gap-32">
        {
          AvatarComp.map(({ title, size, data }) => {
            return (
              <CompLayout
                component={<Avatar avatarData={data} avatarGroup size={size} />}
                title={title}
                desc="Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default DefaultGroup