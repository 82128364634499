import React from 'react'
import Radio from '../../theme/Elements/Radio/Radio'
import CompLayout from '../../theme/Layout/CompLayout'

function DefaultRadio() {
  const radioSizes = [
    { size: 'large', title: 'large' },
    { size: undefined, title: 'normal' },
    { size: 'small', title: 'small' }
  ];

  return (
    <div>
      <h3 className="section-title">Default</h3>
      <p className="comp-desc mt-12">
        Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a
        page. Primary buttons should only appear once per container (not including the application header or in a modal
        dialog). Not every screen requires a primary button.
      </p>
      <div className="d-flex mt-32 flex-column gap-32">
        {radioSizes.map(({ size, title }) => (
          <CompLayout
            key={title}
            component={<Radio size={size} />}
            title={title}
            desc="Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
          />
        ))}
      </div>
    </div>
  )
}

export default DefaultRadio