import React from 'react'
import style from './comp_layout.module.scss';
import { CopyIcon } from '../icons';
function CompLayout({component,title,desc,code}) {
  return (
    <div className={style["comp-wrapper"]}>
<h6 className={style["comp-title"]}>{title}</h6>
<p className='comp-desc mt-12'>{desc}.</p>
<div className={style["card-wrapper"]}>
<div className={style["comp-container"]}>
 {component}
</div>
<div className={style["code-container"]}>
<div>
<h6 className={style["code-title"]}>Js</h6>
  <pre>
    <code className={style["code-snippet"]}>
{`import { Button, Flex } from 'antd';
const App = () => (
    <Button type="primary">Primary Button</Button>
);
export default App;`}
    </code>
  </pre>
</div>
<span className={style["copy-btn"]}>
<CopyIcon />
</span>
</div>
</div>
<div className={style["footer-text"]}>
<p className={style["link-info"]}>See all states</p>
<h4 className={style["footer-link"]}>usage</h4>
</div>
    </div>
  )
}

export default CompLayout