import React from 'react'
import DefaultTextArea from '../components/TextArea/DefaultTextArea'
import FluidTextArea from '../components/TextArea/FluidTextArea'

function TextAreaComp() {
  return (
    <div className="d-flex flex-column gap-52">
<DefaultTextArea />
<FluidTextArea />
    </div>
  )
}

export default TextAreaComp