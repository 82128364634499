import React from 'react'
import NormalToaster from '../components/Toaster/NormalToaster'

function ToasterComp() {
  return (
    <div className="d-flex flex-column gap-52">
<NormalToaster />
    </div>
  )
}

export default ToasterComp