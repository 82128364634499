import React, { useState } from 'react';
import Button from '../Button/Button';
import { TickIcon, ToasterCloseIcon } from '../../icons';

function Toaster({prefix,closable,action,actionFooter}) {
  const [showToaster, setShowToaster] = useState(false);

  const handleButtonClick = () => {
    setShowToaster(true);
    setTimeout(() => {
      setShowToaster(false);
    }, 2000); 
  };

  return (
    <>
      <Button text='click' variant="primary" size="lg" onClick={handleButtonClick} />
      {/* {showToaster && ( */}
        <div className={`toaster ${showToaster ? "active" : ""}`}>
        {
prefix && (
  prefix == true || false ? <span className="">
            <TickIcon />
          </span> :
          prefix
)
        }
          <div className={`toaster-msg-container ${actionFooter ? 'toaster-action-footer' : ""}`}>
            <h6 className="toaster-msg">Message</h6>
{
action && <Button variant="link" text='Action'/>
}
          </div>
          {
            closable && <span onClick={()=>setShowToaster(false)} className="toaster-close-icon">
              <ToasterCloseIcon />
            </span>
          }
        </div>
      {/* )} */}
    </>
  );
}

export default Toaster;
