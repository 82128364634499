import React from 'react'
import Avatar from '../../theme/Elements/Avatar/Avatar'
import CompLayout from '../../theme/Layout/CompLayout'

function AvatarSizes() {
  const avatarComp = [
    {title : "Double Extra Large",size:"xxl"},
    {title : "extra large",size:"xl"},
    {title : "large",size:"lg"},
    {title : "normal",},
    {title : "dense",size:"sm"},
    {title : "denser",size:"xs"},

    {title : "Double Extra Large",size:"xxl",content:"icon"},
    {title : "extra large",size:"xl",content:"icon"},
    {title : "large",size:"lg",content:"icon"},
    {title : "normal",content:"icon"},
    {title : "dense",size:"sm",content:"icon"},
    {title : "denser",size:"xs",content:"icon"},

    {title : "Double Extra Large",size:"xxl",content:"text"},
    {title : "extra large",size:"xl",content:"text"},
    {title : "large",size:"lg",content:"text"},
    {title : "normal",content:"text"},
    {title : "dense",size:"sm",content:"text"},
    {title : "denser",size:"xs",content:"text"},
    
  ]
  return (
    <div>
    <h3 className="section-title">sizes</h3>
    <p className="comp-desc mt-12">Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.</p>
    <div className="d-flex mt-32 flex-column gap-32">
    {
      avatarComp.map(({title,size,content})=>{
        return(
          <CompLayout
            key={title}
            component={<Avatar content={content} size={size}/>}
            title={title}
            desc="Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
          />
        )
      })
    }
    </div>
  </div>
  )
}

export default AvatarSizes